<template>
  <!-- 查看火车票 选地区、时间   不可下单-->
  <BuyTrainUI @searchTrain="searchTrain" @chooseTrainStation="chooseTrainStation" />
</template>

<script>
import BuyTrainUI from "./components/BuyTrainUI";
export default {
  name: "BuyTrainView",
  components: { BuyTrainUI},
  methods: {
    // 搜索按钮
    searchTrain() {
      this.$router.push("/searchTrainView");
    },

    // 选择城市  参数是出发、到达城市的vuex方法名，丢到query即可
    chooseTrainStation(vuxFunction) {
      this.$router.push({
        path: "/selectCity",
        query: { function: vuxFunction, allCity: true }
      });
    },
  }
};
</script>
